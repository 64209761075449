/* eslint-disable no-loop-func */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import style from "./style.module.scss";
import { withStyles } from "@material-ui/core/styles";
import { getDayOfWeek, getDeviceShortName, getRange, formatNumber, getCurrentDate, getDeviceColor, filterHomeStatsDevices } from "../../../../utils";
import { Lens as LensIcon } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@material-ui/icons";
import { fieldNameFromStoreName } from "@apollo/client/cache/inmemory/helpers";

interface Props {
  queryDevicesList: any;
  queryAggregatedMonthDeltas: any;
  queryShiftsList: any;
  onOpenTooltip: any;
  onCloseTooltip: any;
  selectedDay: string | undefined;
  range: any;
  onClickPreviousMonth: any;
  onClickNextMonth: any;
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 500,
    fontSize: "0.8rem",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 8px 0 #00000033",
    color: "#222222",
    padding: "1rem",
  },
  arrow: {
    color: "#ffffff",
  },
}))(Tooltip);

interface TooltipContentProps {
  date: string;
  devices: Array<any>;
  shifts: Array<any>;
  dayShiftsData: any;
  onCloseTooltip: any;
}

const TooltipContent = (props: TooltipContentProps): JSX.Element => {
  return (
    <>
      <table className={style.detailsTable}>
        <tbody>
          <tr>
            <td>
              <span className={style.detailsLabel}>Delco</span>
            </td>
            {
              props.shifts.map((shift: string, index: number): JSX.Element => (
                <td key={`shift_details_label_${shift}`}>
                  <span className={style.detailsLabelCenter}>Turno {index + 1}</span>
                </td>
              ))
            }
          </tr>
          {
            props.devices.filter(filterHomeStatsDevices).map((device: string): JSX.Element => (
              <tr key={`device_details_row_${device}`}>
                <td>
                  <span style={{ color: getDeviceColor(device) }} className={style.detailsLabel}>{getDeviceShortName(device)}</span>
                </td>
                {
                  props.shifts.map((shift: string): JSX.Element => (
                    <td key={`shift_details_label_${shift}`}>
                      <span style={{ color: getDeviceColor(device) }} className={style.detailsValue} key={`shift_details_row_${shift}`}>
                        {props.dayShiftsData[device][shift].product ? `${props.dayShiftsData[device][shift].product}: ` : ""}{formatNumber(props.dayShiftsData[device][shift].total)}
                      </span>
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className={style.detailsActions}>
        <span className={style.detailsClose} onClick={props.onCloseTooltip}>Chiudi</span>
      </div>
    </>
  );
};

const getDayShiftsData = (devices: Array<string>, day: string, data: string, shifts: Array<string>): any => {
  const parsedData = JSON.parse(data);
  const result: any = {};
  devices.filter(fieldNameFromStoreName).forEach((device: string): void => {
    result[device] = {};
    shifts.forEach((shift: string): void => {
      let total = 0;
      const dayShiftsData = parsedData[device][day];
      Object.keys(dayShiftsData[shift]).filter((element: string) => element !== "product").forEach((element: string): any => total += parseFloat(dayShiftsData[shift][element]));
      result[device][shift] = { ...dayShiftsData[shift], total };
    });
  });
  return result;
};

const getDayTotal = (shifts: any): number => {
  let result = 0;
  Object.keys(shifts).forEach((shift: any) => {
    result += parseFloat(shifts[shift].total);
  });
  return result;
};

const getDaysCountInMonth = (date: string): number => {
  const year = parseInt(date.substr(0, 4));
  const month = parseInt(date.substr(5, 2));

  return new Date(year, month, 0).getDate();
};

const Main = (props: Props): JSX.Element => {
  const onClickExport = (): void => {
    const saveFile = (rows: any, filename: any): any => {
      const blob = new Blob([rows], { type: "text/plain;charset=utf-8" });
      const URL_ = (window.URL || window.webkitURL);
      const fileURL = URL_.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", fileURL);
      a.setAttribute("download", filename);
      a.click();
      a.remove();
      setTimeout(function () {
        URL_.revokeObjectURL(fileURL);
      }, 60000);
    };
    let rows = "'Data';'Delco';'Turno';'Totale';Prodotto;\n";
    props.queryDevicesList.data.devicesList.forEach((device: string): void => {
      const formattedData = Object.keys(JSON.parse(props.queryAggregatedMonthDeltas.data.aggregatedMonthDeltas.data)[props.queryDevicesList.data.devicesList[0]])[0];
      const numberOfDays = getDaysCountInMonth(formattedData);
      const datePrefix = props.range.start.substring(0, 8);
      for (let day = 1; day < numberOfDays; day++) {
        const shifts = getDayShiftsData(
          props.queryDevicesList.data.devicesList,
          props.range.start.slice(0, 8) + `00${day}`.slice(-2),
          props.queryAggregatedMonthDeltas.data.aggregatedMonthDeltas.data,
          props.queryShiftsList.data.shiftsList,
        );
        props.queryShiftsList.data.shiftsList.forEach((shift: any, index: number): void => {
          const dayString = ("00" + day).slice(-2);
          rows += `'${datePrefix}${dayString}';'${device}';'${index + 1}';'${shifts[device][shift].total}';'${shifts[device][shift].product}';\n`;
        });
      }
    });
    saveFile(rows, "export.csv");
  };

  if (
    props.queryDevicesList.loading ||
    !props.queryDevicesList.data ||
    props.queryShiftsList.loading ||
    !props.queryShiftsList.data
  ) {
    return <CircularProgress />;
  }

  let monthProgressiveTotal = 0;
  const devicesMonthTotals: any = {};
  const today: string = getCurrentDate();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {
          props.queryAggregatedMonthDeltas.loading || !props.queryAggregatedMonthDeltas.data ? <CircularProgress /> : (
            <Paper elevation={2} className={style.box}>
              <div className={style.header}>
                <div>
                  <Button variant="contained" color="primary" onClick={props.onClickPreviousMonth}><ArrowBackIcon /></Button>
                </div>
                <div className={style.headerTitle}>
                  <span>{props.range.start.slice(0, 7)}</span>
                  <div>
                    <Button variant="contained" color="primary" onClick={onClickExport}>Esporta</Button>
                  </div>
                </div>
                <div>
                  <Button variant="contained" color="primary" onClick={props.onClickNextMonth}><ArrowForwardIcon /></Button>
                </div>
              </div>
              <div className={style.calendarContainer}>
                <div className={style.calendarContent}>
                  <div className={style.monthColumnsHeaders}>
                    <b>Lun</b>
                    <b>Mar</b>
                    <b>Mer</b>
                    <b>Gio</b>
                    <b>Ven</b>
                    <b>Sab</b>
                    <b>Dom</b>
                  </div>
                  <div className={style.monthContainer}>
                    <div className={style.month}>
                      {
                        getRange(1, 6).map((empty: number): JSX.Element | undefined => {
                          const parsedData = JSON.parse(props.queryAggregatedMonthDeltas.data.aggregatedMonthDeltas.data);
                          const dayOfWeek = getDayOfWeek(Object.keys(parsedData[props.queryDevicesList.data.devicesList[0]])[0]);
                          if (empty < dayOfWeek) {
                            return <div key={`empty_${empty}`} />;
                          }
                          return undefined;
                        })
                      }
                      {
                        getRange(1, parseInt(props.range.end.slice(8, 10))).map((day: number): JSX.Element => {
                          const date = props.range.start.slice(0, 8) + `00${day}`.slice(-2);
                          const daysShiftsData: any = getDayShiftsData(
                            props.queryDevicesList.data.devicesList.filter(filterHomeStatsDevices),
                            date,
                            props.queryAggregatedMonthDeltas.data.aggregatedMonthDeltas.data,
                            props.queryShiftsList.data.shiftsList,
                          );
                          let dayTotal = 0;
                          const currentDate = date.substring(0, 10);
                          return (
                            <HtmlTooltip
                              key={`day_${day}`}
                              placement="top"
                              arrow
                              open={props.selectedDay === date}
                              interactive
                              disableFocusListener
                              disableHoverListener
                              title={
                                <TooltipContent
                                  dayShiftsData={daysShiftsData}
                                  shifts={props.queryShiftsList.data.shiftsList}
                                  devices={props.queryDevicesList.data.devicesList.filter(filterHomeStatsDevices)}
                                  date={date}
                                  onCloseTooltip={props.onCloseTooltip}
                                />
                              }
                            >
                              <div
                                className={props.selectedDay === date ? style.selectedDay : style.day}
                                onClick={(): void => { if (today >= currentDate) props.onOpenTooltip(date); }}
                              >
                                <div className={style.dayNumber}>
                                  <span>{date.slice(-2)}</span>
                                  {today === currentDate ? <div><LensIcon /></div> : <></>}
                                </div>
                                {
                                  today >= currentDate ? (
                                    <>
                                      {
                                        props.queryDevicesList.data.devicesList.filter(filterHomeStatsDevices).map((device: string): JSX.Element => {
                                          const deviceDayTotal: number = getDayTotal(daysShiftsData[device]);
                                          dayTotal += deviceDayTotal;
                                          monthProgressiveTotal += deviceDayTotal;
                                          if (devicesMonthTotals[device] === undefined) devicesMonthTotals[device] = 0;
                                          devicesMonthTotals[device] += deviceDayTotal;
                                          return (
                                            <div key={`day_item_${device}`} className={style.dayItem}>
                                              <span style={{ color: getDeviceColor(device) }} className={style.dayLabel}>{getDeviceShortName(device)}</span>
                                              <span style={{ color: getDeviceColor(device) }} className={style.dayValue}>{formatNumber(deviceDayTotal)}</span>
                                            </div>
                                          );
                                        })
                                      }
                                      <div className={style.dayItemTotal}>
                                        <span className={style.dayLabel}>Day total</span>
                                        <span className={style.dayValue}>{formatNumber(dayTotal)}</span>
                                      </div>
                                      <div className={style.dayItemTotal}>
                                        <span className={style.dayLabel}>Month total</span>
                                        <span className={style.dayValue}>{formatNumber(monthProgressiveTotal)}</span>
                                      </div>
                                    </>
                                  ) : <></>
                                }
                              </div>
                            </HtmlTooltip>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className={style.monthTotals}>
                    <div className={style.monthTotalsColumns}>
                      <div>
                        <span className={style.monthTotalsTitle}>Progressivo del mese</span>
                        {
                          Object.keys(devicesMonthTotals).map((key: string): JSX.Element => (
                            <div key={`device_total_${key}`}>
                              <span style={{ color: getDeviceColor(key) }} className={style.monthTotalsLabel}>{getDeviceShortName(key)}</span>
                              <span style={{ color: getDeviceColor(key) }} className={style.monthTotalsValue}>{formatNumber(devicesMonthTotals[key])}</span>
                            </div>
                          ))
                        }
                      </div>
                      <div>
                        <span className={style.monthTotalsTitle}>Totale Generale</span>
                        <span className={style.monthTotalsValue}>{formatNumber(monthProgressiveTotal)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          )
        }
      </Grid>
    </Grid>
  );
};

export default Main;
